
import { fetchOpenOrders, fetchHybridOrders } from "../../Actions/OrderActions";
import {paymentStatus } from "../../static/index";
import { formatOrders } from "../../misc/formatter";
import lotImage from "@/../assets/images/Box.svg"
import { useRouter } from "vue-router";
import { formatCurrency } from "../../misc/formatter";
import configuration from "../../config.json";

export default {
    data() {
        return {
            type: 'Hybrid',
            orders: [],
            page: 1,
            totalPages: 1,
            totalCount: 0,
        }
    },
    computed: {
        getAllPages() {
            return this.totalPages;
        }
    },
    setup() {
        const router = useRouter();
        const handleUpdate = (value) => {
            router.push(router.currentRoute.value.path + `?mode=${value}`)
        }

        const handlePageUpdate = (page) => {
            const { mode = "Open" } = router.currentRoute.value.query;
            router.push(router.currentRoute.value.path + `?mode=${mode}&page=${page}`)
        }

        let bidOptions = ['Open', 'Hybrid']
        if(configuration.Lots.MOQ.enabled) {
            bidOptions.push('MOQ')
        }

        return {
            handleUpdate,
            handlePageUpdate,
            formatCurrency,
            configuration,
            bidOptions
        }
    },
    watch: {
        '$router': {
            handler: function(path) {
              const pageQuery = path.currentRoute.value.query
              const modeVal = pageQuery?.mode ? pageQuery.mode : 'Hybrid';
              this.type = modeVal === 'Moq' ? 'MOQ' : modeVal;
             },
            deep: true,
            immediate: true
        },
        type() {
            this.totalPages = 1;
            this.page = 1;
            this.fetchOrders()
        },
        page() {
            window.scrollTo(0,0)
            this.fetchOrders()
        },
    },
    methods: {
        shippIcon(status = "Payment Pending") {
            return status === "Cancelled" ? 'mdi-close-circle-outline' : status !== 'Payment Success' ? 'mdi-information-outline' : 'mdi-check-circle';
        },
        paymentIcon(status = "Payment Pending") {
            return status === 'Payment Success' ? 'mdi-check-circle' : 'mdi-information-outline';
        },
        getClasses(status = "Payment Pending", className = '') {
            let classes = className;
            switch(status) {
                case 'Cancelled': {
                    return `${classes} cancelled`
                }
                case 'Payment Success': {
                    return `${classes} success`
                }
                case 'Payment Pending': {
                    return `${classes} pending`
                }
                default: {
                    return `${classes} pending`
                }
            }

            return classes;
        },
        statusColor(status = "Payment Pending") {
            return paymentStatus[status] === 'Success' ? "#029B21" : (paymentStatus[status] === "Cancelled" ? "#EC0101" : "#646464")
        },
        orderImg(item = {}) {
            const imgData = item.small_image || item.lot_image_urls || []
            const url = imgData.length > 0 ? imgData[0] : lotImage
            return url.replace("http://qa-docker.blubirch.com:3550//", "https://")
        },
        getValue(obj, attr) {
            return (!obj[attr] && obj[attr] !== 0) ? "N/A" : obj[attr];
        },
        getOpenOrders() {
            fetchOpenOrders({page: this.page, per_page: 10}).then(res => {
                if(res.status === 200) {
                    this.orders = formatOrders(res?.data?.orders);
                    this.totalCount = res.data?.meta?.total_count;
                    this.totalPages = parseInt(parseInt(res.data?.meta?.total / 10) + ((res.data?.meta?.total % 10) > 0 ? 1 : 0)) || 1;  
                } else {
                    this.orders = [];
                    this.totalCount = 0;
                    this.totalPages = 1;
                }
            })
        },
        getHybridOrders() {
            fetchHybridOrders({
                "mode": this.type?.toLocaleLowerCase(),
                "search": {
                    "text": ""
                },
                "page": this.page,
                "sort": "-updated_at",
                "per_page": 10
            }).then(res => {
                if(res.status === 200) {
                    this.orders = formatOrders(res?.data?.results);
                    this.totalCount = res?.data?.meta?.total_count
                    this.totalPages = res?.data?.meta?.total_pages
                } else {
                    this.orders = [];
                    this.totalCount = 0;
                    this.totalPages = 1;
                }
            })
        },
        fetchOrders() {
            this.type !== 'Open' ? this.getHybridOrders() : this.getOpenOrders();
        },
        redirectToDetails(order) {
            let url = order?.bid_id ? `/product_detail/${order.lot_publish_id}?bid_id=${order?.bid_id}` : `/product_detail/${order.lot_publish_id}`
            this.$router.push(url);
        },
    },
    mounted() {
        if(this.$router.currentRoute?.value?.query?.mode) {
            const modeVal = this.$router.currentRoute?.value?.query?.mode
            this.type = modeVal === 'Moq' ? 'MOQ' : modeVal;
        }
        
        if(this.$router.currentRoute?.value?.query?.page) {
            this.page = parseInt(this.$router.currentRoute?.value?.query?.page)
        }
        this.fetchOrders();
    }
}
